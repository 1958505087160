import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { getIn } from "formik";

interface FormTextFieldProps {
  name: string;
  label: string;
  formik: any;
  defaultValue?: string;
  multiline?: boolean;
  // Include all properties from TextFieldProps
  [key: string]: any;
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  label,
  formik,
  defaultValue,
  multiline,
  ...props
}) => {
  return (
    <TextField
      fullWidth
      multiline={multiline}
      id={name}
      name={name}
      variant="outlined"
      placeholder="Enter name and surname"
      defaultValue={defaultValue}
      value={getIn(formik?.values, name)}
      onChange={formik?.handleChange}
      onBlur={formik?.handleBlur}
      error={getIn(formik?.touched, name) && !!getIn(formik?.errors, name)}
      helperText={getIn(formik?.touched, name) && getIn(formik?.errors, name)}
      sx={{ borderRadius: 5 }}
      inputProps={{
        style: {
          height: 38,
          borderRadius: 5,
          padding: "0 8px",
        },
        className: props.className
      }}
      {...props}
    />
  );
};

export default FormTextField;
