import * as React from 'react';
import { makeStyles } from "@mui/styles";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { logoNameToPath } from "../constants/logos";
import useTranslation from '../hooks/translations';

const useStyles = makeStyles((theme: any) => ({
  image: {
    width: 30,
    height: 20,
  },
  paymentMethodName: {
    flexGrow: 3,
    fontWeight: 500,
    fontSize: 14,
    textAlign: "left",
    alignSelf: "left",
    width: "100%",
  },
  paymentOption: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    width: "100%",
    border: "solid 1px #E0E0E0",
    padding: "9px 10px",
    borderRadius: 10,
    marginTop: 20,
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
  },
}));

interface PaymentProvider {
  name: string;
}

interface PaymentOptionProps {
  paymentProvider: PaymentProvider;
  onClick: (paymentProvider: PaymentProvider, optionChangeObject: any) => void;
  dataTestId: string;
  optionChangeObject: any;
}

const PaymentOption: React.FC<PaymentOptionProps> = ({ paymentProvider, onClick, optionChangeObject, dataTestId }) => {
  const classes = useStyles();
  const {t} = useTranslation()
  const translatedPaymentOptions: any = {
    "Paypal": t("paypal"), 
    "Mobile Money": t("mobileMoney"),
    "Card": t("creditCard")
  }
  
  return (
    <div className={classes.paymentOption} onClick={(e) => onClick(paymentProvider, optionChangeObject)} data-testid={dataTestId}>
      <div>
        <img src={logoNameToPath[paymentProvider.name]} alt={paymentProvider.name} className={classes.image} />
      </div>
      <p className={classes.paymentMethodName}>{translatedPaymentOptions[paymentProvider.name]}</p>
      <div>
        <ArrowForwardIosOutlinedIcon fontSize="small" color="inherit" />
      </div>
    </div>
  );
};

export default PaymentOption;