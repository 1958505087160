import React from "react";
import useUI from "../../../../hooks/useUI";
import { FormControl, Grid, Typography } from "@mui/material";
import FormTextField from "../../../../components/FormTextField";

import { FormikProps } from 'formik';
import useTranslation from "../../../../hooks/translations";


interface EmailSectionProps {
  formik: FormikProps<any>;
}

const EmailSection: React.FC<EmailSectionProps> = ({ formik }) => {
  const {t} = useTranslation()
  return (
    <Grid item xl={10} md={12}>
      <Grid item>
        <FormControl className="form-control" fullWidth>
          <Typography
            component="legend"
            className={"fieldTitleSecondary"}
          >
            {t("email")} <span style={{fontSize: 14, color: "red"}}>*</span>
          </Typography>
          <Grid item className={"inputWrapper"}>
            <FormTextField
              fullWidth
              id="notification.email"
              name="notification.email"
              variant="outlined"
              formik={formik}
              className={"input MuiInputBase-input email"}
              placeholder={t("enterEmailAdrress")}
              label="Email"
            />
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EmailSection;
