import React from "react";
import {
  FormControl,
  Grid,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import useTranslation from "../../../../hooks/translations";

interface ProviderSectionProps {
  payment: any;
  formik: FormikProps<any>;
  providerType: string;
  setPaymentProviderId: (id: string) => void;
  setPricingId: (id: string) => void;
  radioChecked: boolean;
  setRadioChecked: (checked: boolean) => void;
  setPaymentProviderName: (name: string) => void;
}

const ProviderSection: React.FC<ProviderSectionProps> = ({
  payment,
  formik,
  providerType,
  setPaymentProviderId,
  setPricingId,
  radioChecked,
  setRadioChecked,
  setPaymentProviderName,
}) => {
  const {t} = useTranslation()

  return (
    <Box>
      <Grid item>
        <FormControl className={"fieldset"} component="fieldset">
          <Typography
            component="legend"
            className={"fieldTitleSecondary"}
          >
            {t("provider")} <span style={{fontSize: 14, color: "red"}}>*</span>
          </Typography>
          {payment
            ?.find((item: any) => item?.name === providerType)
            ?.paymentOptions?.map(
              (i: any) =>
                i?.paymentProviderInfo?.enabled && (
                  <RadioGroup
                    key={i?.paymentProviderInfo?.id?.uuid}
                    aria-required={true}
                    className={"radioBlockWrapper"}
                    name="paymentProviderId"
                    aria-label="payment"
                    row
                    onChange={() =>
                      formik.setFieldValue(
                        "paymentProviderId",
                        i?.paymentProviderInfo?.id
                      )
                    }
                    onBlur={formik?.handleBlur}
                    value={getIn(formik?.values, "paymentProviderId.uuid")}
                  >
                    <FormControlLabel
                      key={i?.paymentProviderInfo?.id?.uuid}
                      id={i?.paymentProviderInfo?.id?.uuid}
                      onClick={() => {
                        setPaymentProviderId(i?.paymentProviderInfo?.id);
                        formik.setFieldValue(
                          "paymentProviderId",
                          i?.paymentProviderInfo?.id
                        );
                        setPricingId(i?.paymentProviderInfo?.customerPricingId);
                        setPaymentProviderName(i?.paymentProviderInfo?.name);
                      }}
                      value={i?.paymentProviderInfo?.id}
                      control={
                        <Radio
                          name={i?.paymentProviderInfo?.name}
                          checked={
                            radioChecked === i?.paymentProviderInfo?.name &&
                            true
                          }
                          onClick={(e: any) => setRadioChecked(e.target.name)}
                          className={`radio radio-${i?.paymentProviderInfo?.name}`}
                        />
                      }
                      label={
                        <img
                          style={{
                            maxWidth: "50px",
                            height: "auto",
                            marginRight: "10px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            borderRadius: "5px",
                          }}
                          src={i?.paymentProviderInfo?.logoUrl}
                          alt={i?.paymentProviderInfo?.name}
                          loading="lazy"
                        />
                      }
                    />
                  </RadioGroup>
                )
            )}
        </FormControl>
        {
          <Typography
            pb={4}
            pl={2}
            style={{
              color: "#d32f2f",
              fontSize: "0.75rem",
            }}
          >
            {typeof formik?.errors.paymentProviderId === 'string' ? formik.errors.paymentProviderId : ''}
          </Typography>
        }
      </Grid>
    </Box>
  );
};

export default ProviderSection;
