import React from "react";
import { FormControl, Grid, Typography } from "@mui/material";
import FormTextField from "../../../../components/FormTextField";

import { FormikProps } from 'formik';
import useTranslation from "../../../../hooks/translations";

interface NameSectionProps {
  formik: FormikProps<any>;
}

const NameSection: React.FC<NameSectionProps> = ({ formik }) => {
  const {t} = useTranslation()
  return (
    <Grid item xl={10} md={12}>
      <Grid item>
        <FormControl className="form-control" fullWidth>
          <Typography
            component="legend"
            className={"fieldTitleSecondary"}
          >
            {t("nameAndSurname")} <span style={{fontSize: 14, color: "red"}}>*</span>
          </Typography>
          <Grid item className={"inputWrapper"}>
            <FormTextField
              fullWidth
              id="notification.customerName"
              name="notification.customerName"
              variant="outlined"
              formik={formik}
              className={"input MuiInputBase-input nameAndSurname"}
              placeholder={t("enterNameAndSurname")}
              label="Name and surname"
            />
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NameSection;
