import FailIcon from "../../assets/images/FailIcon.svg";
import * as React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import "./style.css";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import useTranslation from "../../hooks/translations";

interface PaymentFailedModalProps {
  transactionId: string;
  isOpen: boolean;
  onClose: () => void;
}

const PaymentFailedModal: React.FC<PaymentFailedModalProps> = ({
  transactionId,
  isOpen,
  onClose,
}) => {
  const { searchResults, errorRequest } =
    React.useContext<GlobalContextType>(GlobalContext);
  const {t, translationObject} = useTranslation();
  const errorMessages: { [key: string]: string } = {
    "00020": t("mobileError"),
    "00004": t("paymentError"),
  };
  const errorMessage =
    (errorRequest?.code && errorMessages[errorRequest.code]) ||
    t("generalError");

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={"box"}>
        <Box className={"imageBox"}>
          <img src={FailIcon} alt="success" />
        </Box>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={"centerText"}
          data-testid="payment-failed"
        >
          {t("paymentFailed")}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          sx={{ color: "#667085" }}
          className={"centerText"}
        >
          {translationObject.formatString(t("paymentFailedWithId"), <span className={"span"}>{transactionId}</span>)} {errorMessage}
        </Typography>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ color: "white", mt: 2 }}
          className={"button"}
        >
          {t("exit")}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ color: "#0AA5B7", mt: 2 }}
          className={"button"}
        >
          {t("return")}
        </Button>
      </Box>
    </Modal>
  );
};

export default PaymentFailedModal;
